.div_container {
  display: grid;
  grid-template-columns: 1fr 2fr 1fr;
  padding-top: 6.2%;
}

.div_mb {
  margin-bottom: 6.2%;
}

.border_bottom {
  padding-bottom: 2vh;
  border-bottom: 0.5px solid #d9d9d9;
}

.btn_back {
  margin-top: 3px;
  width: 8.7px;
  height: 16px;
  margin-left: 19px;
}

.btn_back:hover {
  cursor: pointer;
}

.btn_close {
  margin-top: 4px;
  width: 14px;
  height: 14px;
  margin-left: 19px;
}

.btn_close:hover {
  cursor: pointer;
}

.div_title_01 {
  /* 꼼삐 */

  /* Sub-title-SB */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 28px;
  /* identical to box height, or 156% */

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  text-align: center;

  display: flex;
  align-items: end;
  justify-content: center;
}

.div_two_title_wrap {
  max-width: 100%;
  margin: 0 auto;
}

.div_two_title_wrap .items {
  margin-top: 5%;
  display: flex;
  text-align: center;
}

.div_two_title_wrap .items .item {
  /* 나란히 배치할 영역 */
  left: 0;
  width: 50%;
  height: 100%;
  text-align: center;
}

.div_two_title_wrap .items .item_activate {
  /* 나란히 배치할 영역 */
  left: 0;
  width: 50%;
  height: 100%;
  text-align: center;
}
.div_two_title_wrap .items .item_left {
  padding-right: 5px;
  color: #343434;
  font-size: 10px;
  font-family: 'Pretendard-Regular', sans-serif;
  font-weight: 600;
  word-wrap: break-word;
}

.div_two_title_wrap .items .item_left:hover {
  cursor: pointer;
}

.div_two_title_wrap .items .item_left_activate {
  padding-right: 5px;
  color: #29b488;
  font-size: 10px;
  font-family: 'Pretendard-Regular', sans-serif;
  font-weight: 600;
  word-wrap: break-word;
}
.div_two_title_wrap .items .item .activate {
  width: 195px;
  margin-top: 5%;

  border: 1.21px solid #4ee0b2;
}

.div_two_title_wrap .items .item_right {
  padding-left: 5px;
  color: #343434;
  font-size: 10px;
  font-family: 'Pretendard-Regular', sans-serif;
  font-weight: 600;
  word-wrap: break-word;
}

.div_two_title_wrap .items .item_right:hover {
  cursor: pointer;
}

.div_two_title_wrap .items .item_right_activate {
  padding-left: 5px;
  color: #29b488;
  font-size: 10px;
  font-family: 'Pretendard-Regular', sans-serif;
  font-weight: 600;
  word-wrap: break-word;
}

.div_two_title_wrap .items .item + .item {
  left: 50%; /* 다음에 올 영역에 left: 50%;을 사용해 나란히 배치 */
}

.div_container p {
  margin-top: 2px;
  text-align: right;
  border: none;
  background-color: unset;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: -0.05em;

  color: #979797;
  margin-right: 17px;
}

.div_container button {
  align-self: start;
  padding: 0px;
  text-align: right;
  border: none;
  background-color: unset;

  margin-right: 17px;
  /* Prevent tap highlight on mobile */
  -webkit-tap-highlight-color: transparent;
}

.div_container button:focus,
.div_container button:active {
  outline: none; /* Remove the focus outline */
  box-shadow: none; /* Remove any box shadow */
}

.div_container p :hover,
.div_container button :hover {
  cursor: pointer;
}

.p_sub_title {
  /* 함께한지 10일째 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 18px;

  color: #1c1c1c;
  margin-top: 6px;
}
