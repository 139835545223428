.div_with_timer {
  width: 78.76%;
  height: 43px;
  background: #f2f2f2;
  border-radius: 77px;
  color: #8d8d8d;
  border: none;
  display: flex;

  vertical-align: middle;
  align-items: center;
}

.border_red {
  border: solid 1px #f00;
}

.div_with_timer input::placeholder {
  font-weight: lighter;
}

.div_with_timer input {
  outline: none;
  height: 83%;
  width: 70%;
  background: #f2f2f2;
  border-radius: 77px;

  border: none;
  font-family: Pretendard;
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;

  color: #1c1c1c;
  padding-left: 24px;
}

.div_with_timer div {
  width: 30%;
  text-align: right;
  vertical-align: middle;
  margin-right: 24px;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
}

.div_with_timer div span {
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 14px;

  color: #00c398;
}

.div_with_timer div img {
  width: 13px;
  vertical-align: middle;
}

.mb_10 {
  margin-bottom: 10px;
}
