.loginBtn {
  padding: 10px 5px;
  margin-top: 10px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Pretendard-Regular';
}

.btn_add_comment {
  /* Frame 340 */

  /* Auto layout */

  width: 100%;
  height: 48px;
  border: none;

  background: #f4f6fa;
  border-radius: 7px;

  /* 장미러버 님에게 댓글 추가... */

  text-align: left;
  padding-left: 24px;

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Gray 04 */
  color: #a2a3a7;
}

.btn_add_plant {
  border: none;
  /* Frame 336 */

  /* Auto layout */
  display: flex;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 51px;

  background: #e0f7f1;
  border-radius: 50px;
  /* 식물 등록하기 */

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;

  letter-spacing: -0.05em;

  color: #00c398;
}

.div_add_plant_short {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 51px;
  height: 36px;

  /* Secondary 01 */
  background: #e0f7f1;
  border-radius: 77px;

  /* Inside auto layout */
  flex: none;
  order: 1;
  flex-grow: 0;
}

.div_add_plant {
  /* 일정 추가 버튼-Default */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  width: 115px;
  height: 36px;

  /* Secondary 01 */
  background: #e0f7f1;
  border-radius: 77px;

  /* Inside auto layout */
  flex: none;
  order: 2;
  flex-grow: 0;
  border: none;
}

.loginMainBtn {
  color: white;
  background-color: #00c398;
  font-size: 16px;
}
.loginKktBtn {
  background-color: #f9e000;
}
.loginNaverBtn {
  color: white;
  background-color: #01c73c;
}

.btn_edit_prof {
  /* Frame 139 */

  box-sizing: border-box;

  width: 100%;

  font-family: 'Pretendard-Regular';

  /* Body 02-M */

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  /* Frame */

  height: 39px;

  /* Gray 02 */
  background: #f4f6fa;
  border-radius: 7px;
  border: none;
}

.btn_submit_01 {
  height: 52px;
  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;
  color: #4ee0b2;

  border-radius: 50px;
  border: solid 1px #00c398;
  background-color: #fff;

  /* Button */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.02em;

  /* White */
  color: #00c398;
}

.btn_submit_01_active {
  height: 52px;

  justify-content: center;
  align-items: center;
  text-align: center;
  display: flex;

  border: none;

  cursor: pointer;

  border-radius: 50px;

  border: solid 1px #00c398;
  background-color: #00c398;

  /* Button */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;

  letter-spacing: -0.02em;

  /* White */
  color: #ffffff;
}

.btn_submit_01:hover {
  cursor: pointer;
}

.btn_chat:hover,
.btn_edit_prof {
  cursor: pointer;
}
.btn_chat {
  padding-left: 27px;
  padding-right: 27px;

  border: none;
  align-items: center;

  width: fit-content;
  height: 39px;

  background: #00c398;
  border-radius: 50px;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  color: #ffffff;
}

.is_sold {
  background: #dadada;
  color: #979797;
}
.btn_with_timer {
  width: 35%;
  height: 100%;
  float: right;

  margin-left: 2.3%;

  font-family: Pretendard;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.65px;

  border-radius: 47px;
  background-color: #fff;
  color: #979797;
  border: solid 1px #dadada;
}

.btn_hash {
  width: 21.166%;
  padding: 0px 0px 0px 0px;
  margin-left: 2.93%;
  background-color: unset;
  /* Frame 184 */

  box-sizing: border-box;

  /* Auto layout */

  align-items: center;

  height: 49px;

  border: 1px solid #dadada;
  border-radius: 7px;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  letter-spacing: -0.05em;

  color: #979797;
  align-self: center;
}

.btn_with_timer:hover,
.btn_hash:hover {
  cursor: pointer;
}

.btn_with_input {
  width: 35%;
  height: 100%;
  float: right;

  margin-left: 2.3%;

  font-family: Pretendard;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.65px;

  color: #00c398;

  border-radius: 47px;

  background-color: #fff;
  color: #979797;
  border: solid 1px #dadada;
}

.btn_with_input:hover {
  cursor: pointer;
}
.mt_19_vh {
  margin-top: 19vh;
}

.mt_51_px {
  margin-top: 51px;
}

.mt_56_percent {
  margin-top: 56.2%;
}
.mt_22p6_percent {
  margin-top: 22.6%;
}

.mt_52_px {
  margin-top: 52px;
}

.active {
  border-radius: 47px;
  border: solid 1px #00c398;
  background-color: #e0f7f1;
  color: #00c398;

  /* 인증번호 전송 */
}

.btn_dual_01 {
  box-sizing: border-box;
  width: 44.4vw;
  height: 51px;

  border: 1px solid #4ee0b2;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.8px;

  color: #00c398;

  border-radius: 50px;
  border: solid 1px #00c398;
  background-color: #fff;
}
.btn_dual_02 {
  box-sizing: border-box;
  width: 44.4vw;
  height: 51px;
  border: none;

  border-radius: 50px;

  font-family: Pretendard;
  font-size: 16px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.8px;

  color: #fff;
  margin-left: 2.6%;

  background-color: #00c398;
}

.btn_dual_03 {
  box-sizing: border-box;
  width: 100%;
  /* Frame 139 */
  height: 39px;
  background: #00c398;
  border-radius: 7px;
  border: none;

  font-family: 'Pretendard-Regular';

  /* Body 02-M */

  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;

  letter-spacing: -0.02em;

  /* White */
  color: #ffffff;
}
.btn_dual_05 {
  box-sizing: border-box;
  width: 100%;
  /* Frame 139 */
  height: 39px;

  /* Gray 02 */
  background: #f4f6fa;
  border-radius: 7px;
  border: none;

  /* Body 02-M */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  /* Frame */
}

.btn_dual_04 {
  background: unset;
  margin-left: 2.6%;
  box-sizing: border-box;
  width: 44.4vw;
  /* Frame 139 */
  height: 34px;

  border-radius: 7px;

  /* Frame 140 */

  box-sizing: border-box;

  border: 1px solid #dadada;

  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: -0.05em;

  color: #1c1c1c;
}

.btn_dual_01:hover {
  cursor: pointer;
}

.btn_dual_02:hover,
.btn_dual_03:hover,
.btn_dual_04:hover {
  cursor: pointer;
}

.btn_diary {
  /* Rectangle 8 */

  width: 317px;
  height: 51px;
  left: 32px;
  top: 702px;

  background: #00c398;
  border-radius: 7px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  /* identical to box height */

  align-items: center;
  letter-spacing: -0.05em;

  color: #ffffff;
  border: none;
}

.btn_diary:hover {
  cursor: pointer;
}

.btn_diary_dual_01 {
  /* Frame 131 */

  box-sizing: border-box;

  /* Body 01-R */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.02em;

  /* Primary */
  color: #00c398;

  width: 116px;
  height: 42px;

  /* Gray 01 */
  background: #f9fbff;
  border-radius: 7px;
  border: none;
}

.btn_diary_dual_01:hover {
  cursor: pointer;
}
.btn_diary_dual_02 {
  /* Frame 132 */

  width: 116px;
  height: 42px;

  background: #00c398;
  border-radius: 7px;

  /* Body 01-R */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  text-align: center;
  letter-spacing: -0.02em;

  /* ✏️ Action */

  color: #ffffff;
  border: none;
  margin-left: 8px;
}

.btn_diary_dual_02:hover {
  cursor: pointer;
}

.btn_diary_detail_cal {
  /* Frame 12 */
  border: none;

  box-sizing: border-box;

  /* Auto layout */

  justify-content: center;
  align-items: center;

  height: 24px;

  background: #f2f2f2;
  border-radius: 77px;

  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
}

.btn_diary_detail_cal:hover,
.btn_full_modal:hover {
  cursor: pointer;
}

.btn_full_modal {
  /* Button */

  /* Auto layout */
  /* _Action-item */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 42px;

  /* Primary */
  background: #00c398;
  border-radius: 7px;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  border: none;

  /* Body 01-R */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */
  display: flex;
  align-items: center;
  text-align: center;
  letter-spacing: -0.02em;

  /* White */
  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
}

.btn_collection_move {
  /* Secondary button */

  box-sizing: border-box;

  /* Auto layout */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  width: 58px;
  background-color: white;
  height: 38px;

  /* Secondary 02 */
  border: 1px solid #b2ebda;
  border-radius: 77px;

  /* Body 02-M */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  /* identical to box height, or 153% */
  letter-spacing: -0.02em;

  /* Primary */
  color: #00c398;
  place-self: center;
  position: absolute;
  right: 34px;
}

.btn_write_diary:hover,
.btn_collection_move:hover {
  cursor: pointer;
}

.collection_selected {
  color: white;
  background-color: #00c398;
  border: none;
}

.btn_write_diary {
  /* 레벨 */

  /* Auto layout */

  justify-content: space-between;
  align-items: center;

  width: 78px;
  height: 32px;
  border: none;

  /* Secondary 01 */
  background: #e0f7f1;
  border-radius: 50px;

  /* Button_small */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.0025em;
  margin-right: 8px;

  /* Black */
  color: #242424;

  /* Inside auto layout */
}
