.div_alert_text {
  height: 1.4vh;
}

.div_alert_text span {
  margin-left: 3.6%;

  font-family: Pretendard;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: left;
  color: #f00;
}
