.loginPageInput {
  width: 100%;
  border-radius: 30px;
  background-color: #f2f2f2;
  padding: 10px 6%;
  border: none;
  box-sizing: border-box;
}

.input_01 {
  width: 100%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 77px;
  color: #8d8d8d;
  border: none;
  font-family: Pretendard;
  font-size: 11.5px;
  font-weight: 500;

  word-wrap: break-word;
  padding-left: 21px;

  touch-action: manipulation; /* 기본 동작 제어 */
}

.input_01:focus {
  outline: none;
}

.input_with_two_button {
  /* identical to box height */

  border: none;

  background-color: unset;

  border: none;

  background-color: unset;

  /* 검색어를 입력해주세요. */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  /* 검색어를 입력해주세요. */
}

.input_with_two_button:focus {
  outline: none;
}

.input_with_two_button_collection {
  /* identical to box height */

  border: none;

  border: none;

  background-color: #f4f6fa;

  /* 검색어를 입력해주세요. */

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* identical to box height, or 157% */
  letter-spacing: -0.02em;

  /* Black */
  color: #242424;

  /* 검색어를 입력해주세요. */
}

.input_with_two_button_collection:focus {
  outline: none;
}
.input_count {
  box-sizing: border-box;

  width: 45px;
  height: 28px;

  background: #ffffff;
  border-radius: 7px;

  align-items: center;
  text-align: center;

  border: none;
  margin-left: 8px;
  margin-right: 8px;

  /* Caption */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
}

.input_count:focus {
  outline: none;
}

.input_small {
  /* Rectangle 157 */

  box-sizing: border-box;

  width: 115px;
  height: 49px;

  background: #ffffff;

  font-family: 'Pretendard-Regular';

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  align-items: center;
  text-align: center;
  letter-spacing: -0.05em;

  color: #1c1c1c;

  width: 109px;
  height: 49px;

  border: 1px solid #dadada;
  border-radius: 7px;
}

.input_small:focus {
  outline: none;
}

.input_with_btn {
  width: 69.82%;
  height: 100%;
  background: #f2f2f2;
  border-radius: 77px;
  border: none;
  color: #8d8d8d;
  font-family: Pretendard;
  font-size: 11.5px;
  font-weight: 500;
  word-wrap: break-word;
  padding-left: 24px;
}
.mb_10 {
  margin-bottom: 10px;
}

.input_error {
  border: 1px solid red;
}

.input_error:focus-visible {
  outline: red;
}

.noneStyle {
  border: none;
  background-color: #f2f2f2;
  box-sizing: border-box;
}

.noneStyle:focus {
  outline: none;
}
