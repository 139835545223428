.div_container_01 {
  width: 100%;
  height: 100%;
  justify-content: center;

  background: white;
  overflow: 'auto';
}

.div_container_02 {
  width: 100%;

  justify-content: center;
  height: auto;
  overflow: hidden;
  background: white;
}

.div_container_03 {
  width: 100%;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  overflow-y: hidden;
  background: white;
}

.div_container_04 {
  align-items: center; /* 수직으로 중앙 정렬 */
  justify-content: center; /* 수평으로 중앙 정렬 */
  width: 100%;
  height: auto;
  background: white;
  overflow: auto; /* 필요에 따라 수정 */
}

.div_container_05 {
  width: 100%;
  height: 100%;
  justify-content: center;

  overflow: hidden;
  background: white;
  overflow-y: hidden;
}

.div_container_06 {
  width: 100%;
  height: 100%;
  justify-content: center;

  background: white;
  overflow: hidden;
  overflow-x: hidden;
}
