.loginPageForm {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 150px;
  padding: 10px 0;
}

.fullWidth {
  width: 100%;
}
.flx_end {
  justify-content: flex-end;
}

.fullH {
  height: 50px;
}

.scroll {
  height: 130%;

  overflow-y: auto;
  overflow-x: hidden;
}
