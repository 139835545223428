.div_counter_wrap {
  display: flex;

  box-sizing: border-box;

  justify-content: center;

  align-items: center;
  align-content: center;

  width: 105px;
  height: 38px;

  /* Gray 02 */
  background: #f4f6fa;
  border-radius: 13px;
}

.img_count:hover {
  cursor: pointer;
}

.img_count {
  cursor: pointer;
  align-items: center;
  align-content: center;
  justify-content: center;
  vertical-align: middle;
}
