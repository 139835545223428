.div_container {
  /* margin: 34px 85px 13px 17px; */
}

.div_label {
  text-align: left;
  /* margin-bottom: 12px; */
  /* display: flex; */
  justify-content: space-between;
  /* margin-right: 40%; */
  align-content: center;
}

.mt_2p7_vh {
  margin-top: 23px;
}

.div_label span {
  text-align: left;

  /* 학명 */

  /* Body 01-M */
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  /* identical to box height, or 150% */

  letter-spacing: -0.02em;

  /* Black */
  color: #242424;
}

.div_info {
  margin-left: 3.6%;
  margin-bottom: 5px;
}

.div_info span {
  font-family: Pretendard;
  font-size: 13px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.5px;
  text-align: left;

  color: #979797;
}

.text_green span {
  color: #00c398;
}

.text_red span {
  color: #f00;
}

.text_15_px {
  font-size: 15px;
}
