/* .transitions-wrapper-left,
.transitions-wrapper-right {
  overflow: hidden;

  height: calc(var(--vh, 1vh) * 100);
  width: 100vw;
} */

.right-enter {
  position: absolute;

  top: 0;
  z-index: 10;
  transform: translateX(100%);

  background-color: white;
}

.right-enter-active {
  position: absolute;

  top: 0;
  z-index: 10;
  transform: translateX(0);

  background-color: white;

  transition: transform 300ms ease-in-out;

  box-shadow: -5px 0px 25px rgba(0, 0, 0, 0.05);
}

.right-exit {
  transform: translateX(0);
}

.right-exit-active {
  transform: translateX(0%);
  /* transition: transform 300ms ease-in-out; */
}

/* .transitions-wrapper-left > .right-exit-active,
.transitions-wrapper-left > .left-exit-active {
  transform: translateX(0%);
  position: absolute;
  z-index: 0;
  top: 0;
 
} */

.left-enter {
  transform: translateX(-30%);
  opacity: 0.95;
}

.left-enter-active {
  transform: translateX(0);
  opacity: 1;
  transition: all 300ms ease-in-out;
}

.left-exit {
  transform: translateX(0);
}

.left-exit-active {
  transform: translateX(100%);
  transition: transform 300ms ease-in-out;
}

.navigate-push-enter {
  /* position: fixed;
  
  /* height: 150vh; */
  /* z-index: 2;

  background-color: white;
  top: 0;  */
  height: var(--window-height);
  transform: translateX(100%);
}

.navigate-push-enter-active {
  height: var(--window-height);
  position: absolute;

  /* top: 0; */
  /* height: 150vh; */
  z-index: 2;

  transform: translateX(0%);
  transition: transform 300ms ease;
  /* 
  background-color: white; */

  /* box-shadow: -5px 0px 25px rgba(0, 0, 0, 0.05); */
}

.navigate-push-exit {
  transform: translateX(0);
}

.navigate-push-exit-active {
  transform: translateX(0%);
  /* transition: transform 300ms ease-in-out; */
}

.transition-wrapper {
  position: relative;
  height: 100%;
  background-color: white;
}
.transition-wrapper-navigate-push {
}
.back-enter {
  transform: translateX(0%);
}

.back-enter-active {
  position: absolute;

  top: 0;
  z-index: 0;
  transform: translateX(0);
  transition: transform 300ms ease-in-out;

  box-shadow: -5px 0px 25px rgba(0, 0, 0, 0.05);
}

.back-exit {
  position: absolute;
  height: 150vh;
  background-color: white;
  top: 0;
  z-index: 2;
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
}

.back-exit-active {
  position: absolute;
  height: 150vh;
  background-color: white;
  top: 0;
  z-index: 2;
  transform: translateX(0);
  transition: transform 300ms ease-in-out;
  transform: translateX(100%);
  /* transition: transform 300ms ease-in-out; */
}

.na-enter {
  position: absolute;

  background-color: white;
  /* display: none; */
}

.na-enter-active {
  position: absolute;
  background-color: white;
  /* display: none; */
}

.na-exit {
  display: none;
  /* visibility: hidden; */
  /* display: none; */
  /* display: none; */
  /* transform: translateY(-100%); */
}

.na-exit-active {
  display: none;
  /* visibility: hidden;
  transform: translateY(-100%); */
}
