.div_container {
  display: flex;
  height: 49px;
  width: 100%;
  margin-bottom: 10px;
}

.div_container span {
  align-content: center;
  font-family: 'Pretendard-Regular';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  /* identical to box height */
  letter-spacing: -0.05em;

  color: #1c1c1c;
  margin-left: 7px;
}

.border_red input {
  border: solid 1px #f00;
}
