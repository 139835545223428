.div_dual_btn {
  display: flex;
  justify-content: center; /* 수평 가운데 정렬 */
}

.mt_13_vh {
  margin-top: 46.2%;
}

.mt_27_percent {
  margin-top: 235px;
}

.mt_20_px {
  margin-top: 20px;
}

.div_dual_btn_left {
  display: flex;
}
