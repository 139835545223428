.div_container {
  display: flex;
  position: relative;
}

.btn_file {
  /* Rectangle 113 */

  box-sizing: border-box;

  width: 66px;
  height: 66px;

  background: #ffffff;
  border: 1px solid #dadada;
  border-radius: 7px;
}

.border_red {
  border: solid 1px #f00;
}

.btn_file :hover {
  cursor: pointer;
}

.btn_file p {
  font-family: 'Pretendard';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;

  text-align: center;

  color: #979797;
}

.div_img_wrap {
  box-sizing: border-box;
  position: relative;
  width: 65px;
  height: 65px;
  background: rgba(28, 28, 28, 0.3);
  border-radius: 7px;
  z-index: 0;
}

.delete_btn {
  position: absolute; /* 버튼을 이미지 위에 위치시키기 위해 absolute 사용 */
  z-index: 2;
  width: 9px;
  height: 9px;
  border: none;
  background-color: unset;
  color: #ffffff;
  top: 7px; /* 이미지의 위쪽에 위치하도록 조정 */
  right: 7px; /* 이미지의 오른쪽에 위치하도록 조정 */
}

.div_img_content {
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  width: 65px;
  height: 65px;
  box-sizing: border-box;
}

.div_img_content img {
  max-width: 65px;
  max-height: 65px;
}

.selected_img {
  position: relative;
  z-index: 1; /* 이미지의 z-index를 1로 설정하여 delete_btn 아래에 배치 */
}
